import { useState } from "react";
import Input from "../../MiroComponents/Input/Input";
import SelectComponent from "../../MiroComponents/SelectComponent/SelectComponent";
import { Button } from "../../MiroComponents/Button/Button";

export default function PageTargetingForm() {
  const [otherData, setOtherData] = useState({
    trigger: null,
    poll_on_rules: false,
    url_targetings: [],
  });
  return (
    <form
      className="default-margin-bottom"
      onSubmit={async (e) => {
        e.preventDefault();
        const { name, trigger_js, rules_js, poll_on_rules } = e.target.elements;
        console.log(
          name.value,
          otherData?.trigger,
          trigger_js.value,
          rules_js.value,
          otherData?.poll_on_rules,
          otherData?.url_targetings
        );
        const urltargetings = otherData?.url_targetings.map(
          ({ __typename, _id, ...rest }) => rest
        );

        //   const pageData = {
        //     client: client,
        //     name: name.value,
        //     trigger: trigger.value,
        //     trigger_js: trigger_js.value || "return true;",
        //     deactivation_mode: "reset",
        //     rules_js: rules_js.value || "return true;",
        //     poll_on_rules: poll_on_rules.checked,
        //     urltargetings: urltargetings,
        //   };
        //   if (formType === "add") {
        //     await createPage({
        //       variables: {
        //         pageData: pageData,
        //       },
        //     });
        //     toast.success("Page Added Successfully");
        //   } else {
        //     await updatePage({
        //       variables: {
        //         id: currentData?._id,
        //         pageData: pageData,
        //       },
        //     });
        //     toast.success("Page Edited Successfully");
        //   }
        //   refetch();
        //   setModule(null);
      }}
    >
      <SelectComponent
        options={[
          {
            value: "direct",
            label: "Direct (immediately when Codebase runs)",
          },
          {
            value: "callback",
            label: "Call back (when callback function is called)",
          },
        ]}
        label="Trigger"
        value={otherData.trigger}
        placeholder="Select Trigger"
        icon={""}
        selectWrapperClass=""
        customClass="default-padding default-border big-margin-bottom bg-2"
        containerClass="full-width"
        onChange={(selected) => {
          setOtherData({ ...otherData, trigger: selected });
        }}
      />
      <Input
        type={"text"}
        value={``}
        inputWrapperClass="full-width medium bg-2"
        label="Page Name"
        placeholder={"Enter Name"}
        name="name"
        // onChange={(e) => {
        //   activateButtonsCallBack(true);
        // }}
      />
      <Input
        type={"text"}
        value={``}
        inputWrapperClass="full-width medium bg-2"
        label="JS trigger"
        placeholder={"return true;"}
        name="trigger_js"
        // onChange={(e) => {
        //   activateButtonsCallBack(true);
        // }}
      />

      <Input
        type={"text"}
        value={``}
        inputWrapperClass="full-width medium bg-2"
        label="Rules for JS code to run"
        placeholder={"return true;"}
        name="rules_js"
        // onChange={(e) => {
        //   activateButtonsCallBack(true);
        // }}
      />

      {/* <div className="fields-wrapper inline-checkbox">
        <div className="checkbox-field-wrapper">
          <input
            type="checkbox"
            name="poll_on_rules"
            //   defaultChecked={currentData?.poll_on_rules ? true : false}
            className="field-control"
          />
        </div>
        <label>Poll on Rules</label>
      </div> */}
      {/* <div className="fields-wrapper fields-wrapper__updated">
        <label>URL Targeting :-</label>
      </div> */}
      {/* {urlTargeting.map((item, index) => (
      <div className="d-flex" style={{ gap: "10px" }} key={index}>
        <div className="fields-wrapper fields-wrapper__updated">
          <label>Type</label>
          <Select
            name={`type_${index}`}
            className="acc-newselectwrapper"
            classNamePrefix="acc-newselect"
            required
            placeholder="Select"
            options={[
              { value: "include", label: "Include" },
              { value: "exclude", label: "Exclude" },
            ]}
            defaultValue={
              item.type ? { value: item.type, label: item.type } : null
            }
            onChange={(selectedOption) =>
              handleChange(selectedOption, index, "type")
            }
          />
        </div>
        <div className="fields-wrapper fields-wrapper__updated">
          <label>url</label>
          <input
            name={`url_${index}`}
            className="field-control  field-control__updated"
            placeholder=""
            required
            defaultValue={item.url}
            onChange={(e) => handleChange(e, index, "url")}
          />
        </div>
        <div className="fields-wrapper fields-wrapper__updated">
          <label>url Type</label>
          <Select
            name={`url_type$_${index}`}
            className="acc-newselectwrapper"
            classNamePrefix="acc-newselect"
            placeholder="Select"
            required
            options={[
              { value: "substring", label: "Sub string" },
              { value: "regex", label: "Regex" },
              { value: "simple", label: "Simple" },
            ]}
            defaultValue={
              item.url_type
                ? {
                    value: item.url_type,
                    label: item.url_type,
                  }
                : null
            }
            onChange={(selectedOption) =>
              handleChange(selectedOption, index, "url_type")
            }
          />
        </div>
        <button
          className="mt-4 w-25 h-25 btn text-white"
          onClick={() => {
            const arr = [...urlTargeting];
            arr.splice(index, 1);
            setUrlTargeting(arr);
          }}
        >
          <DeleteUrlIcon />
        </button>
      </div>
    ))} */}
      <div className="d-flex justify-content-end">
        <Button
          onClick={() => {
            const arr = [...otherData.url_targetings];
            arr.push({
              type: "",
              url: "",
              url_type: "",
            });
            setOtherData({ ...otherData, url_targetings: arr });
          }}
          className="smart-button small"
        >
          Add New Target
        </Button>
      </div>

      <div className="add__new__test__form-footer">
        <Button
          className="smart-button medium active"
          type="Submit"
          text={`Create Page`}
        />
      </div>
    </form>
  );
}
