import moment from "moment";
import { formatUserPlatform, parseFilters, PlatForm } from "./Utilities";
import { differenceInCalendarDays } from "date-fns";

export const getFormatedContentForHistories = async ({ historyData }) => {
  const today = moment();
  let resultContent = "";
  // let resultContent = `*ACC_TestUpdate ${today.format("DD.MM.YYYY.")}*\n`;
  // resultContent += `Here is your weekly update on the results of the currently running tests.\n`;
  // resultContent += `\n\nSummary\n\n`;
  const formatValue = (value) => {
    const num = Number(value);
    if (num % 1 === 0) {
      return num.toLocaleString("en-US", {
        minimumFractionDigits: 0,
      });
    }

    return num.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const combinedTestMessages = await Promise.all(
    historyData?.map(async (data) => {
      const refetchData = data?.reportData?.refetchTemporaryReportBigQuery;
      const variantData = refetchData?.calculatedResults;
      const { totalTransactions } = refetchData?.totalOfResults;
      const daysCount = data?.daysCount;
      const platforms = parseFilters(data?.filters);
      const testName = data?.testName;
      const currency = data?.currency;

      let messages = "";

      if (!variantData?.length) {
        messages += `>Test has been running for *${daysCount}* days, you will receive an update for this next week.\n\n`;
      } else {
        //Generate messages for each variant comparison
        const variantMessages = await Promise.all(
          variantData?.map((variant) => {
            if (!variant?.audienceName) return; // Skip empty audienceName

            const controlDataLast = refetchData?.result?.find(
              (ref) =>
                ref.controlvariant === "Reference" ||
                ref.controlvariant === "reference"
            );

            const vDataLast = refetchData?.result?.find(
              (ref) => ref.controlvariant === variant.audienceName
            );

            const {
              changeInConversionRate,
              additionalTransactionsPerMonth,
              additionalRevenuePerYear,
            } = variant;
            const isCRPositive =
              parseFloat(changeInConversionRate?.replace(/%/g, "").trim()) < 0;

            const rpuChange =
              Number(vDataLast?.calculatedAverageRevenuePerUser) <
              Number(controlDataLast?.calculatedAverageRevenuePerUser)
                ? "Decrease"
                : "Increase";
            const aovChange =
              Number(vDataLast?.calculatedAveragePurchaseRevenue) <
              Number(controlDataLast?.calculatedAveragePurchaseRevenue)
                ? "Decrease"
                : "Increase";
            let variantMessage = `*${testName}*\n`;
            variantMessage += `>💡\n`;
            console.log("platforms", platforms);
            variantMessage += `>The ${
              isCRPositive ? "Control" : "Variant"
            } is performing better on: *${formatUserPlatform(platforms)}*\n`;

            variantMessage += `>RPU: ${rpuChange} from *${currency}${formatValue(
              controlDataLast?.calculatedAverageRevenuePerUser
            )}* to *${currency}${formatValue(
              vDataLast?.calculatedAverageRevenuePerUser
            )}*\n`;
            variantMessage += `>Sample size: *${formatValue(
              totalTransactions
            )}* transactions\n`;
            variantMessage += `>AOV: ${aovChange} from *${currency}${formatValue(
              controlDataLast?.calculatedAveragePurchaseRevenue
            )}* to *${currency}${formatValue(
              vDataLast?.calculatedAveragePurchaseRevenue
            )}*\n`;

            variantMessage += `>Change in CR: *${
              isCRPositive ? "" : "+"
            }${formatValue(
              parseFloat(changeInConversionRate.replace("%", "").trim())
            )}%*\n`;

            if (additionalTransactionsPerMonth > 0) {
              variantMessage += `>💲\n`;
              variantMessage += `>Additional transactions per month, at current traffic: *+${formatValue(
                additionalTransactionsPerMonth
              )}* transactions\n`;
              variantMessage += `>Additional revenue per year, at current AOV: *${currency}${formatValue(
                additionalRevenuePerYear
              )}*\n`;
            }
            // Add test duration message
            variantMessage += `>⏳\n`;
            variantMessage += `>The test has been running for: *${Math.abs(
              30 - daysCount
            )}* days\n`;
            variantMessage += `>The test can be evaluated in: *${daysCount}* days\n`;
            return variantMessage;
          })
        );
        messages += variantMessages?.join("\n\n");
      }
      return messages;
    })
  );
  resultContent += combinedTestMessages?.join("\n\n");
  //   resultContent += `\n\nIf you have any questions, ideas or comments, let me know and I’ll be happy to help!_
  // _Info/replies in the thread_"`;
  return resultContent;
};
