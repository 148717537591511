import { NavLink } from "react-router-dom";
import { Button } from "../../MiroComponents/Button/Button";
import { DropIcon, TickGreen } from "../../Shared/Icons";
import MyRoutes from "../../../Routes";

export default function AbTestOverview({ insights }) {
  return (
    <div className="default-padding">
      <div className="onboarding overview">
        <ul className="d-flex default-padding default-column-gap">
          <li className="default-border default-padding d-inline-flex default-column-gap align-items-center radius-4">
            <div className="count">{insights?.experimentsCount}</div>
            <div className="border-left default-padding-left">
              <h3 className="default-margin-bottom neon">Experiments</h3>
              <NavLink to={`${MyRoutes.abtestingdashboard}/experiments`}>
                Go to Experiments
              </NavLink>
            </div>
          </li>
          <li className="default-border default-padding d-inline-flex default-column-gap align-items-center radius-4">
            <div className="count">{insights?.pagesCount}</div>
            <div className="border-left default-padding-left">
              <h3 className="default-margin-bottom neon">Page Targeting</h3>
              <NavLink to={`${MyRoutes.abtestingdashboard}/pagetargetings`}>
                See Page Targetings
              </NavLink>
            </div>
          </li>
          <li className="default-border default-padding d-inline-flex default-column-gap align-items-center radius-4">
            <div className="count">{insights?.audiencesCount}</div>
            <div className="border-left default-padding-left">
              <h3 className="default-margin-bottom neon">Audience Targeting</h3>
              <NavLink to={`${MyRoutes.abtestingdashboard}/audiencetargetings`}>
                Go to Audiences
              </NavLink>
            </div>
          </li>
          <li className="default-border default-padding d-inline-flex default-column-gap align-items-center radius-4">
            <div className="count">{insights?.goalsCount}</div>
            <div className="border-left default-padding-left">
              <h3 className="default-margin-bottom neon">Goals</h3>
              <NavLink to={`${MyRoutes.abtestingdashboard}/goals`}>
                Goals
              </NavLink>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
