import "./Input.scss";
export default function Input({
  type,
  placeholder,
  label,
  name,
  icon,
  value,
  wrapperClass = "",
  inputWrapperClass = "",
  onChange,
  component,
  children,
  ...props
}) {
  if (component) return <>{children}</>;
  return (
    <div className={`field-wrapper ${wrapperClass}`}>
      {label && <label> {label}</label>}

      <div className={`input-wrapper ${inputWrapperClass}`}>
        {icon && <span>{icon}</span>}
        <input
          {...props}
          name={name}
          type={type}
          placeholder={placeholder}
          defaultValue={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
