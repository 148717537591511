import Highlight from "react-highlight";
import { Button } from "../../MiroComponents/Button/Button";
import "./TargetDefination.scss";
import Input from "../../MiroComponents/Input/Input";

export default function TargetDefination({ targetSelected, ...props }) {
  return (
    <div className="full-width">
      <h3 className="default-margin-bottom">
        {props?.name}
        {props?.type && (
          <>
            : <Button className="smart-pill small active" text={props?.type} />
          </>
        )}
      </h3>
      {targetSelected === "audience" && (
        <div className="default-border default-padding bg-2 full-width">
          <Highlight innerHTML={false} className={"javascript"}>
            {props?.rules_js}
          </Highlight>
        </div>
      )}
      {targetSelected === "goals" && (
        <ul className="default-border default-padding bg-2">
          <li className="d-flex align-items-center default-column-gap">
            <span>Counting Method : </span>
            <Button
              className="smart-pill small active"
              text={props?.counting_method}
            />{" "}
          </li>
        </ul>
      )}
      {props?.urltargetings && props?.urltargetings.length > 0 && (
        <ul className="default-border default-padding bg-2 default-row-gap d-flex flex-column">
          {props?.urltargetings.map((url, index) => (
            <li key={index} className="default-padding-top">
              <div className="d-inline-flex flex-wrap">
                <div className="default-margin-bottom">
                  <span> Condition : </span>
                  <Button
                    className={`smart-pill small default-margin-right 
                    ${url?.type === "include" ? `active` : `notactive`}
                  `}
                    text={url?.type}
                  />{" "}
                </div>
                <div>
                  <span>Match Type : </span>
                  <Button
                    className="smart-pill small active"
                    text={url?.url_type}
                  />
                </div>
              </div>
              <div className="default-margin-top">
                URL :{" "}
                <span className={url?.type === "include" ? `neon` : `red`}>
                  {url?.url}{" "}
                </span>
              </div>
            </li>
          ))}
        </ul>
      )}
      {/* {props?.rules_js && (
        <div className="full-width">
          <Input
            label="Rules"
            value={props?.rules_js}
            wrapperClass="full-width margin-bottom-auto"
            inputWrapperClass="full-width small"
          />
        </div>
      )} */}
      {/* <p className="">
        Check for data in live reporting module after 24 hours of running an
        experiment.
      </p> */}
    </div>
  );
}
