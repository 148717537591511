import React from "react";
import useAbTestStore from "../../../AbTestStore";
import { Button } from "../../MiroComponents/Button/Button";
import NoDataFound from "../../MiroComponents/NoDataFound/NoDataFound";
import PageTargetingForm from "./PageTargetingForm";

export default function PageTargetings() {
  const { insights, updateTest } = useAbTestStore();
  const pageBoxRef = React.useRef(null);
  const PageBox = React.forwardRef(({ page }, ref) => {
    return (
      <section
        className={`default-border radius-4 big-margin-bottom`}
        ref={ref}
      >
        <div className="d-flex justify-content-between default-padding bg-2">
          <h2 className="margin-bottom-auto">{page?.name ?? `Name`} </h2>
          <p>{/* {moment(page?.createdAt).format("LL")} */}</p>
        </div>
        <div className="default-padding">
          <div className="d-flex justify-content-between align-items-center">
            <div>Hii</div>
          </div>
        </div>
      </section>
    );
  });

  return (
    <div>
      <PageTargetingForm />
      <div className="d-flex justify-content-between default-margin-bottom">
        <h1 className="margin-bottom-auto">Pages </h1>
        <div>
          <Button className="smart-button small active">Add New Page</Button>
        </div>
      </div>
      {insights?.pages?.length === 0 && (
        <NoDataFound
          title="No Pages Found"
          description="Add new targetings pages to start testing."
        />
      )}
      {insights?.pages?.map((page, index) => (
        <PageBox page={page} ref={pageBoxRef} key={index} />
      ))}
    </div>
  );
}
