import { useLazyQuery, useMutation } from "@apollo/client";
import { format, subDays } from "date-fns";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import {
  DEACTIVATEREPORT,
  GETNEWGOOGLEANALYTICSDATA,
  REFETCHCLIENTREPORT,
  SAVESEGMENTFILTERS,
  UNSAVESEGMENTFILTERS,
  UPDATESEGMENTFILTERS,
} from "../../Graphql/Mutations";
import {
  GETCLIENTSNAMEANDIDS,
  GETCLIENTSREPORTSHISTORY,
  GETSEGMENTS,
  SAVEDSEGMENTFILTERS,
} from "../../Graphql/Queries";
import {
  daysToEvaluateTest,
  experimentEndResults,
  OperatorValues,
} from "../../Libs/Utilities";
import MyLoader from "../MiroComponents/PreLoader";
import { ClientCalculatedKpis } from "../NewComponents/ClientCalculatedKpis";
import { ClientTimeSeriesandProbability } from "../NewComponents/ClientGraphs/ClientTimeSeriesandProbability";
import { ImpressionsTable } from "../NewComponents/ImpressionsTable";
import { LiveTestsList } from "../NewComponents/LiveTestsList";
import { DateRangeSelectorComponent } from "../Shared/DateRangeSelectorComponent";
import {
  CrossIcon,
  LiveReportsIcon,
  TimesCross,
  FilterIcon,
} from "../Shared/Icons";
import { Scrubber } from "../Shared/Scrubber";
import { SignicanceCalculator } from "../Shared/SignifanceCalculator";
import { CreateReport } from "./Admin/AdminComponents/CreateReport";
import ReactTooltip from "react-tooltip";

export const LiveTestsReporting = ({ clients, platform }) => {
  const _ = require("lodash");
  const [myProfile, is_client] = useOutletContext();
  const [showCalendar, setShowCalendar] = useState(false);
  const [calculatedResults, setCalculatedResults] = useState(null);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [segmentForReport, setSegmentForReport] = useState(null);
  const [clientProperties, setClientProperties] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [client, setClient] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedTest, setSelectedTest] = useState(null);
  const [selectedTestId, setSelectedTestId] = useState(null);
  const [segmentOptions, setSegmentOptions] = useState(null);
  const [scrubber, showScrubber] = useState(null);
  const [segmentFilter, setSegmentFilter] = useState(null);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [segmentValue, setSegmentValue] = useState(null);
  const [reportDetails, setReportDetails] = useState(null);
  const [currentReportType, setCurrentReportType] = useState(null);
  const [loader, setLoader] = useState(false);
  const [savedSegment, setSavedSegment] = useState(null);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [reportsHistory, setReportsHistory] = useState(null);
  // const [getActiveClients] = useLazyQuery(GETCLIENTSNAMEANDIDS);
  const [getSegments] = useLazyQuery(GETSEGMENTS);
  const [getsavedSegments] = useLazyQuery(SAVEDSEGMENTFILTERS);
  const [getreportshistory] = useLazyQuery(GETCLIENTSREPORTSHISTORY);
  const [savesegmentfilters] = useMutation(SAVESEGMENTFILTERS);
  const [deletereporthistory] = useMutation(DEACTIVATEREPORT);
  const [unsavesegmentfilters] = useMutation(UNSAVESEGMENTFILTERS);
  const [updatesegmentfilters] = useMutation(UPDATESEGMENTFILTERS);
  const [refetchreport] = useMutation(REFETCHCLIENTREPORT);
  const [getgoogleanalyticsdata] = useMutation(GETNEWGOOGLEANALYTICSDATA);

  // const setAllClientsOptions = async () => {
  //   const { data, loading, error } = await getActiveClients({
  //     variables: {
  //       clientFilters: [{ active: true  }],
  //     },
  //   });
  //   if (!loading && data) {
  //     setClientSelectorOptions(returnClientOptions(data?.clients?.clients));
  //   }
  // };

  const resetStates = () => {
    setSelectedTest(null);
    setReportDetails(null);
  };

  const setSegmentOptionsQuery = async (client) => {
    const query = {};
    if (client) {
      query["client"] = client;
    }
    const { data } = await getSegments({
      variables: {
        searchString: query,
      },
    });
    setSegmentOptions(data?.segments);
  };

  // const setLimitedClients = (clients) => {
  //   setClientSelectorOptions(returnClientOptions(clients));

  // };
  // useEffect(() => {
  //   if (is_client) {
  //     setLimitedClients(myProfile?.client);
  //   } else {
  //     setAllClientsOptions();
  //   }
  // }, [is_client, myProfile]);

  useEffect(() => {
    if (clients?.length === 1) {
      setClient(clients[0]);
      const properties = JSON.parse(clients[0]?.value);
      const gaproperties = properties?.gaproperties?.filter((property) => {
        return !property?.platform || property?.platform === platform;
      });
      setClientProperties(gaproperties);
      setClientId(properties?._id);
      if (gaproperties?.length === 1) {
        setSelectedProperty({
          label: gaproperties[0]?.propertyname,
          value: gaproperties[0]?.viewId,
        });
      }
    }
  }, [clients]);

  useEffect(() => {
    if (clientId) {
      if (is_client) {
        setSegmentOptionsQuery(clientId);
      } else {
        setSegmentOptionsQuery(null);
      }
    }
  }, [is_client, myProfile, clientId]);

  const getReportsHistoryQuery = useCallback(async () => {
    if (selectedProperty && selectedTestId && clientId) {
      const { data, loading, error } = await getreportshistory({
        variables: {
          client: clientId,
          viewId: selectedProperty.value,
          test: selectedTestId,
        },
      });
      setReportsHistory({
        data: data?.getCachedAnalyticsData,
        loading,
        error,
      });
      ReactTooltip.rebuild();
    }
  }, [selectedProperty, selectedTestId, clientId]);

  useEffect(() => {
    getReportsHistoryQuery();
  }, [getReportsHistoryQuery]);

  const querySavedSegment = async (test) => {
    const { data, loading, error } = await getsavedSegments({
      variables: {
        test,
      },
    });
    setSavedSegment({
      savedSegment: data?.savedSegmentFilter,
      loading,
      error,
    });
    if (data?.savedSegmentFilter?.segmentFilters !== undefined) {
      setSelectedSegments((oldsegments) => [
        ...oldsegments?.filter((item) => item?.key === "ga:experimentId"),
        ...data?.savedSegmentFilter?.segmentFilters?.filter(
          (item) => item?.key !== "ga:experimentId"
        ),
      ]);
    }
  };

  const UpdateSegments = ({ currentSegments, savedSegments, test }) => {
    if (_.xorBy(savedSegments, currentSegments, "value.label")?.length > 0) {
      return (
        <span>
          <button
            className="update-segment-button"
            onClick={async (e) => {
              const { data, error } = await updatesegmentfilters({
                variables: {
                  test,
                  segmentFilters: currentSegments,
                },
              });
              if (error) {
                toast.error(error?.message);
              }
              if (data) {
                querySavedSegment(test);
              }
            }}
          >
            Update Segment
          </button>
        </span>
      );
    }
    return ``;
  };

  useEffect(() => {
    if (selectedTestId) {
      querySavedSegment(selectedTestId);
    }
  }, [selectedTestId]);

  const ClientSelector = ({ options }) => {
    return (
      <div className="fields-wrapper">
        <Select
          onChange={(client) => {
            setClient(client);
            const properties = JSON.parse(client?.value)?.gaproperties;
            setClientProperties(
              properties?.filter(
                (p) => !p?.platform || p?.platform === platform
              )
            );
            // setClientProperties(JSON.parse(client?.value)?.gaproperties);
            setClientId(JSON.parse(client?.value)?._id);
            setSelectedProperty(null);
            setSelectedTestId(null);
            resetStates();
          }}
          value={client}
          // isMulti={true}
          className="acc-newselectwrapper"
          classNamePrefix="acc-newselect"
          placeholder="Name"
          options={options}
        />
      </div>
    );
  };
  const PropertySelector = ({ clientProperties }) => {
    return (
      <div className="fields-wrapper">
        <Select
          onChange={(propertyvalue) => {
            setSelectedProperty(propertyvalue);
            setSelectedTestId(null);
          }}
          isDisabled={!client ? true : false}
          value={selectedProperty}
          className="acc-newselectwrapper"
          classNamePrefix="acc-newselect"
          placeholder="Choose One"
          options={clientProperties?.map((c) => {
            return {
              label: c?.propertyname,
              value: c?.viewId,
            };
          })}
        />
      </div>
    );
  };

  const selectSegments = (key, value, oldSegments, oper) => {
    const oldValue = oldSegments.find((itm) => itm.key === key);
    let arr = [...oldSegments];
    if (!oldValue) {
      arr.push({
        key,
        operator: oper,
        value,
      });
    } else {
      arr = arr.filter((itm) => itm["key"] !== key);
      arr.push({
        key,
        operator: oper,
        value,
      });
    }
    setSelectedSegments(arr);
  };
  const removeSegment = (key, oldSegments) => {
    let arr = [...oldSegments];
    arr = arr.filter((itm) => itm["key"] !== key);
    setSelectedSegments(arr);
    if (key === "ga:experimentId") {
      setSelectedTest(null);
      setReportDetails(null);
    }
  };

  const getSelectValue = (key, oldSegments) => {
    const oldValue = oldSegments.find((itm) => itm.key === key);
    if (oldValue) {
      return oldValue?.value;
    } else {
      return null;
    }
  };

  useEffect(() => {
    let arr = [...selectedSegments];
    if (
      selectedTest?.testtool !== "Kameleoon" &&
      selectedTest?.testtool !== "Vwo"
    ) {
      const oldValue = arr.find((itm) => itm["key"] === "ga:experimentId");
      if (!oldValue) {
        if (selectedTest) {
          arr.push({
            key: "ga:experimentId",
            operator: "==",
            value: {
              label: selectedTest?.testid,
              value: selectedTest?.testid,
            },
          });
        }
      } else {
        arr = arr.filter((itm) => itm["key"] !== "ga:experimentId");
        if (selectedTest) {
          arr.push({
            key: "ga:experimentId",
            operator: "==",
            value: {
              label: selectedTest?.testid,
              value: selectedTest?.testid,
            },
          });
        }
      }
      setReportDetails(null);
      setCalculatedResults(null);
      setSelectedSegments(arr);
    }
  }, [selectedTest, setSelectedTest]);

  const callBackWhenClose = () => {
    setShowCalendar(false);
  };

  const clientCurrency = (clientDetails) => {
    const { currency } = JSON.parse(clientDetails);
    return currency ?? "";
  };

  const getReportsDataFromServer = async (recache) => {
    let metrics = [
      "ga:sessions",
      "ga:transactions",
      "ga:transactionsPerSession",
      "ga:transactionsPerUser",
      "ga:transactionRevenue",
      "ga:revenuePerTransaction",
      "ga:revenuePerUser",
      "ga:users",
    ];
    let dimensions = ["ga:date"];
    let kameleoonFilters = [];
    setLoader(true);
    let queryobj = {
      view_id: selectedProperty?.value,
      start_date: format(selectionRange[0]?.startDate, "yyyy-MM-dd"),
      end_date: format(selectionRange[0]?.endDate, "yyyy-MM-dd"),
      recache: recache ?? false,
    };
    let finalFilters = "";
    if (selectedTest?.testtool === "Kameleoon") {
      kameleoonFilters =
        selectedTest?.kameleoontestdetails?.controlvariants?.map((itm) => {
          return `ga:dimension${selectedTest?.kameleoontestdetails?.dimension}=@${itm?.name}`;
        });
      if (selectedSegments?.length > 0) {
        finalFilters = `${kameleoonFilters.join(",")};${[
          ...selectedSegments?.slice()?.map((itm) => {
            return `${[itm.key]}${itm?.operator}${itm?.value?.value}`;
          }),
        ].join(";")}`;
      } else {
        finalFilters = `${kameleoonFilters.join(",")}`;
      }
      dimensions = dimensions.concat([
        `ga:dimension${selectedTest?.kameleoontestdetails?.dimension}`,
      ]);
    } else if (selectedTest?.testtool === "Vwo") {
      kameleoonFilters = [
        `ga:eventCategory==${selectedTest.vwotestdetails?.vwoeventcategory}`,
        `ga:eventAction=@${selectedTest.name}`,
      ].join(";");
      if (selectedSegments?.length > 0) {
        finalFilters = `${kameleoonFilters};${[
          ...selectedSegments?.slice()?.map((itm) => {
            return `${[itm.key]}${itm?.operator}${itm?.value?.value}`;
          }),
        ].join(";")}`;
      } else {
        finalFilters = `${kameleoonFilters}`;
      }
      dimensions = dimensions.concat([
        "ga:eventCategory",
        "ga:eventAction",
        "ga:eventLabel",
      ]);
    } else {
      finalFilters = [
        ...selectedSegments?.slice()?.map((itm) => {
          return `${[itm.key]}${itm?.operator}${itm?.value?.value}`;
        }),
      ].join(";");

      dimensions = dimensions.concat([
        "ga:experimentId",
        "ga:experimentVariant",
      ]);
    }
    let _segments = null;
    if (selectedSegments?.length === 0) {
      _segments = "All Users";
    } else {
      _segments = selectedSegments
        ?.map((segment) => {
          return `${segment.key}=${segment?.value?.value}`;
        })
        .join(",");
    }
    setSegmentForReport(_segments);
    queryobj["filters"] = finalFilters;
    queryobj["dimensions"] = dimensions;
    queryobj["metrics"] = metrics;
    queryobj["codebaseTestId"] = selectedTest?._id;
    try {
      const { data } = await getgoogleanalyticsdata({
        fetchPolicy: "no-cache",
        variables: {
          options: queryobj,
        },
      });
      const _ = experimentEndResults(
        data?.getAnalyticsData?.response?.experiments,
        selectionRange
      );
      setReportDetails(data?.getAnalyticsData?.response);
      setCalculatedResults(_);
      getReportsHistoryQuery();
      setCurrentReportType({
        type: "live",
      });
      setLoader(false);
    } catch (error) {
      setLoader(false);
      alert(error?.message);
    }
  };

  return (
    <div>
      <Scrubber showScrubber={showScrubber} scrubber={scrubber} />
      <section className="live-reports">
        <div className="live-reports-inner">
          <div className="report-head-main">
            <span className="live-icon">
              <LiveReportsIcon />
            </span>
            <h1 className="section-heading-title">Live Reports {platform}</h1>
          </div>
          <div className="client-data-wrapper">
            <div className="client-details">
              <div className="client-dropdown">
                <h1 className="section-heading">Store:</h1>
                <ClientSelector options={clients} />
              </div>
              <div className="properties-dropdown">
                <h1 className="section-heading">Properties:</h1>
                <PropertySelector clientProperties={clientProperties} />
              </div>
            </div>

            {clientId && selectedProperty && (
              <div className="experiment-details">
                <h1 className="section-heading">experiments:</h1>
                <LiveTestsList
                  client={clientId}
                  viewId={selectedProperty?.value}
                  selectedTest={selectedTest}
                  selectedTestId={selectedTestId}
                  setSelectedTest={setSelectedTest}
                  setSelectedTestId={setSelectedTestId}
                  selectionRange={selectionRange}
                  setSelectionRange={setSelectionRange}
                  setReportDetails={setReportDetails}
                />
              </div>
            )}
          </div>
        </div>
      </section>
      {/* Experiment Data  */}
      {clientId && selectedProperty && selectedTest && (
        <section className="selected-test-content live-reports-content">
          <div className="experiment-data-inner">
            <div className="experiment-heading-divider">
              <div className="section-heading-data">
                <h1 className="section-heading">Experiment Data</h1>
                <span className="section-heading-subtitle">(to-date)</span>
              </div>
              {/* filter icon for mobile screens as general filters will be removed in mobile view */}
              <div className="reporting-mobile-filters">
                <FilterIcon />
                <span className="reporting-mobile-filters__text">Filter</span>
              </div>
              <div
                className="ab-compare"
                onClick={(e) => {
                  showScrubber({
                    control: selectedTest?.controlimage,
                    variation: selectedTest?.variationimage,
                  });
                }}
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 21.3333V14C4 13.1159 4.35119 12.2681 4.97631 11.643C5.60143 11.0179 6.44928 10.6667 7.33333 10.6667C8.21739 10.6667 9.06523 11.0179 9.69036 11.643C10.3155 12.2681 10.6667 13.1159 10.6667 14V21.3333M10.6667 16H4M16 8V24M21.3333 21.3333V10.6667H25.3333C26.0406 10.6667 26.7189 10.9476 27.219 11.4477C27.719 11.9478 28 12.6261 28 13.3333C28 14.0406 27.719 14.7189 27.219 15.219C26.7189 15.719 26.0406 16 25.3333 16H21.3333M25.3333 16C26.0406 16 26.7189 16.281 27.219 16.781C27.719 17.2811 28 17.9594 28 18.6667C28 19.3739 27.719 20.0522 27.219 20.5523C26.7189 21.0524 26.0406 21.3333 25.3333 21.3333H21.3333"
                    stroke="#F6F6F6"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <span>Compare</span>
              </div>
            </div>
            <div className="experiment-data-navbar">
              <div className="experiment-data-dropdown-pane">
                {segmentOptions?.map((segment, i) => (
                  <div className="experiment-dropdown-item" key={i}>
                    <span className="experiment-dropdown-text">
                      <Select
                        placeholder={segment?.name}
                        className="acc-newselectwrapper"
                        value={getSelectValue(segment?.key, selectedSegments)}
                        onChange={(val) => {
                          selectSegments(
                            segment?.key,
                            val,
                            selectedSegments,
                            "=="
                          );
                        }}
                        classNamePrefix="acc-newselect"
                        options={segment?.values?.map((val) => {
                          return {
                            label: val,
                            value: val,
                          };
                        })}
                      />
                    </span>
                  </div>
                ))}
              </div>
              <div className="date-selector">
                <DateRangeSelectorComponent
                  showCalendar={showCalendar}
                  setShowCalendar={setShowCalendar}
                  selectionRange={selectionRange}
                  setSelectionRange={setSelectionRange}
                  className="dark-calendar"
                  classNameWrapper="dark-calendar-wrapper"
                  callBackWhenClose={callBackWhenClose}
                  showDays={true}
                />
              </div>
            </div>
            <div className="experiment-segment">
              <div className="segment-data">
                <div className="segment-dropdown-value">
                  <div className="experiment-dropdown-text">
                    <Select
                      className="acc-newselectwrapper"
                      placeholder="Segment"
                      value={segmentFilter}
                      onChange={(val) => {
                        setSegmentFilter(val);
                      }}
                      classNamePrefix="acc-newselect"
                      options={[
                        {
                          label: "Transactions",
                          value: "ga:transactions",
                        },
                        {
                          label: "Transaction Revenue",
                          value: "ga:transactionRevenue",
                        },
                      ]}
                    />
                  </div>
                  <div className="experiment-dropdown-text">
                    <Select
                      className="acc-newselectwrapper"
                      placeholder="Equals to"
                      value={selectedOperator}
                      onChange={(val) => {
                        setSelectedOperator(val);
                      }}
                      classNamePrefix="acc-newselect"
                      options={OperatorValues()}
                    />
                  </div>
                  <div className="segment-input">
                    <input
                      type="number"
                      id="segmentValue"
                      defaultValue={segmentValue ?? ""}
                      onChange={(e) => setSegmentValue(e.currentTarget.value)}
                      placeholder="Value $ ..."
                      className="segment-search"
                    />
                  </div>
                  <div className="segment-button">
                    <button
                      onClick={(e) => {
                        if (segmentValue && segmentFilter && selectedOperator) {
                          selectSegments(
                            segmentFilter?.value,
                            {
                              label: segmentValue,
                              value: segmentValue,
                            },
                            selectedSegments,
                            selectedOperator?.value
                          );
                          document.querySelector("#segmentValue").value = "";
                          setSegmentFilter(null);
                          setSelectedOperator(null);
                          setSegmentValue(null);
                        }
                      }}
                    >
                      {" "}
                      Add
                    </button>
                  </div>
                </div>
                <div className="segment-btn">
                  <button
                    className="save-segment-btn"
                    onClick={async (event) => {
                      event.preventDefault();
                      getReportsDataFromServer(true);
                    }}
                  >
                    Live Reports
                  </button>
                </div>
              </div>
            </div>
            <div className="selected-segment">
              <div className="selected-data-wrapper">
                {selectedSegments?.map((segment, i) => (
                  <div className="selected-data" key={i}>
                    <span
                      className="remove-data-icon"
                      onClick={(e) =>
                        removeSegment(segment?.key, selectedSegments)
                      }
                    >
                      <CrossIcon />
                    </span>
                    <span className="selected-data-name">
                      {segment?.key}
                      {segment?.operator}
                      {segment?.value?.label}
                    </span>
                  </div>
                ))}
              </div>
              <div className="save-segment">
                {savedSegment?.savedSegment?.segmentFilters !== undefined && (
                  <UpdateSegments
                    currentSegments={selectedSegments}
                    savedSegments={savedSegment?.savedSegment?.segmentFilters}
                    test={selectedTestId}
                  />
                )}

                <span className="save-segment-title">
                  {savedSegment?.savedSegment?.segmentFilters !== undefined
                    ? `Unsave Segments`
                    : `Save this Segment`}
                </span>
                <label
                  className={
                    savedSegment?.savedSegment?.segmentFilters !== undefined
                      ? `checked`
                      : ``
                  }
                  htmlFor="switch"
                  onClick={async (e) => {
                    if (
                      savedSegment?.savedSegment?.segmentFilters === undefined
                    ) {
                      try {
                        const { data, loading, error } =
                          await savesegmentfilters({
                            variables: {
                              test: selectedTestId,
                              segmentFilters: selectedSegments,
                            },
                          });
                        if (data) {
                          querySavedSegment(selectedTestId);
                        }
                      } catch (error) {
                        toast.error(error?.message);
                      }
                    } else {
                      try {
                        const { data, loading, error } =
                          await unsavesegmentfilters({
                            variables: {
                              test: selectedTestId,
                            },
                          });
                        if (data) {
                          querySavedSegment(selectedTestId);
                        }
                      } catch (error) {
                        toast.error(error?.message);
                      }
                    }
                  }}
                ></label>
              </div>
            </div>
          </div>

          {reportsHistory?.data?.length > 0 && (
            <div className="experiment-data-inner">
              <div className="experiment-heading-divider">
                <div className="section-heading-data">
                  <h1 className="section-heading">History</h1>
                  {/* <span className="section-heading-subtitle">(to-date)</span> */}
                </div>
              </div>
              <div className="experiment-data-navbar history-tabs">
                <Splide
                  className="splide-theme-codebase"
                  aria-label="My Favorite Images"
                  options={{
                    type: "slide",
                    perPage: 3,
                    pagination: false,
                    arrows: true,
                    gap: "10px",
                    breakpoints: {
                      1279: {
                        pagination: true,
                        arrows: false,
                      },
                      991: {
                        perPage: 2,
                      },
                      767: {
                        perPage: 1,
                        pagination: true,
                        arrows: false,
                      },
                    },
                  }}
                >
                  {reportsHistory?.data?.map((history, i) => (
                    <SplideSlide key={i}>
                      <div
                        key={i}
                        className={
                          history?._id === currentReportType?.id
                            ? `currentactivehistory`
                            : ``
                        }
                      >
                        <div>
                          <span className="rp-context">
                            <span data-tip={history.filters}>
                              {history.filters}
                            </span>
                            <span
                              className="remove-hitsory-tab"
                              onClick={async (e) => {
                                await deletereporthistory({
                                  variables: {
                                    id: history?._id,
                                  },
                                });
                                getReportsHistoryQuery();
                                toast.success("History Deleted!");
                              }}
                            >
                              {" "}
                              <TimesCross />
                            </span>
                          </span>
                          <span className="rp-ctas">
                            <span className="history-date">
                              {moment(history?.updatedAt).fromNow()}
                            </span>
                            <div className="history-controllers-btns">
                              <button
                                className={
                                  history?._id === currentReportType?.id &&
                                  currentReportType?.type === "history"
                                    ? `active`
                                    : ``
                                }
                                onClick={(e) => {
                                  setCurrentReportType({
                                    id: history?._id,
                                    type: "history",
                                  });
                                  const _ = experimentEndResults(
                                    history.report.experiments,
                                    [
                                      {
                                        startDate: history.report.start_date,
                                        endDate: history.report.end_date,
                                      },
                                    ]
                                  );
                                  setReportDetails(history.report);
                                  setCalculatedResults(_);
                                }}
                              >
                                History
                              </button>
                              <button
                                className={
                                  history?._id === currentReportType?.id &&
                                  currentReportType?.type === "live"
                                    ? `active`
                                    : ``
                                }
                                onClick={async (e) => {
                                  setLoader(true);
                                  setCurrentReportType({
                                    id: history?._id,
                                    type: "live",
                                  });
                                  const _daterange = [
                                    {
                                      startDate: new Date(
                                        selectedTest?.livedate
                                      ),
                                      endDate: new Date(),
                                    },
                                  ];
                                  setSelectionRange(_daterange);
                                  const { data, loading } = await refetchreport(
                                    {
                                      variables: {
                                        id: history?._id,
                                        endDate: moment(new Date()).format(
                                          "yyyy-MM-DD"
                                        ),
                                      },
                                    }
                                  );
                                  setLoader(loading);
                                  const _ = experimentEndResults(
                                    data?.refetchTemporaryReport?.response
                                      ?.experiments,
                                    _daterange
                                  );
                                  setReportDetails(
                                    data?.refetchTemporaryReport?.response
                                  );
                                  setCalculatedResults(_);
                                  getReportsHistoryQuery();
                                }}
                              >
                                Refetch
                              </button>
                            </div>
                          </span>
                        </div>
                      </div>
                    </SplideSlide>
                  ))}
                </Splide>
              </div>
            </div>
          )}

          {loader ? (
            <>
              <MyLoader />
            </>
          ) : (
            <>
              {reportDetails && (
                <>
                  <div className="impression-table">
                    <ImpressionsTable
                      name="Total"
                      type="total"
                      users={reportDetails?.experiments?.reduce(
                        (total, item) => total + Number(item?.users),
                        0
                      )}
                      experiments={reportDetails?.experiments?.reduce(
                        (total, item) => total.concat(item?.experiments),
                        []
                      )}
                    />
                    {reportDetails?.experiments?.map((exp, i) => (
                      <ImpressionsTable
                        name={exp?.controlvariant}
                        type="single"
                        users={exp?.users}
                        experiments={exp?.experiments}
                        key={i}
                      />
                    ))}
                  </div>
                  <div className="experiment-data-inner">
                    {calculatedResults?.map((data, i) => (
                      <ClientCalculatedKpis
                        key={i}
                        data={data}
                        currency={clientCurrency(client?.value)}
                      />
                    ))}

                    <div className="tests-canbe-evaluated">
                      {daysToEvaluateTest(selectionRange)}
                    </div>

                    <ClientTimeSeriesandProbability
                      experiments={reportDetails?.experiments}
                      calculatedResults={calculatedResults}
                    />
                    {!is_client && currentReportType?.type === "live" && (
                      <CreateReport
                        reportsDetails={reportDetails}
                        experimentResults={calculatedResults}
                        selectedTest={selectedTest}
                        client={clientId}
                        selectedTestId={selectedTestId}
                        currency={clientCurrency(client?.value)}
                        selectedProperty={selectedProperty}
                        from={selectionRange[0]?.startDate}
                        to={selectionRange[0]?.endDate}
                        segmentNameForReport={segmentForReport}
                        setSelectedTest={setSelectedTest}
                        setSelectedTestId={setSelectedTestId}
                      />
                    )}
                  </div>
                </>
              )}
            </>
          )}

          {!is_client && reportDetails && (
            <SignicanceCalculator
              viewId={selectedProperty?.value}
              startDate={selectionRange[0]?.startDate}
              endDate={selectionRange[0]?.endDate}
              selectedSegments={selectedSegments}
              selectedTest={selectedTest}
            />
          )}
        </section>
      )}
    </div>
  );
};
